import { t } from "@/core/i18n";
import { RouteRecordRaw, RouterView } from "vue-router";

export const route: RouteRecordRaw = {
  name: "policiesManagement",
  path: "/policies-management/",
  component: RouterView,
  redirect: { name: "policies" },
  meta: {
    breadcrumb: [
      { title: t("policies-management"), routeName: "policiesManagement" },
    ],
  },
  children: [
    {
      path: "",
      name: "policies",
      component: () => import("./screens/Policies.vue"),
      meta: {
        breadcrumb: [
          { title: t("policies-management"), routeName: "policiesManagement" },
          { title: t("policies"), routeName: "policies" },
        ],
      },
    },
    {
      path: "create-policy",
      name: "createPolicy",
      component: () => import("./screens/CreatePolicy.vue"),
      meta: {
        breadcrumb: [
          { title: t("policies-management"), routeName: "policiesManagement" },
          { title: t("policies"), routeName: "policies" },
          { title: t("create-policy"), routeName: "createPolicy" },
        ],
      },
    },
    {
      path: "update-policy/:id",
      name: "updatePolicy",
      component: () => import("./screens/UpdatePolicy.vue"),
      meta: {
        breadcrumb: [
          { title: t("policies-management"), routeName: "policiesManagement" },
          { title: t("policies"), routeName: "policies" },
          { title: t("update-policy"), routeName: "updatePolicy" },
        ],
      },
    },
  ],
};
