import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Users?UserType=3";

const MODULE_TITLE = t("teacher-users");

const NAME = "teachers";

const settings = {
  hasView: true,
  hasExport: false,
  hasBlock: true,
  hasDelete: false,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  rowsOptions: {
    hasView: true,
    hasBlock: true,
    hasDelete: false,
    hasEdit: false,
  } as RowsOptions,
};
const tableHeader: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("name"),
    value: "name",
  },
  {
    label: t("grade"),
    value: "Grade",
  },
  {
    label: t("school"),
    value: "School",
  },
  {
    label: t("account"),
    value: "Account",
  },
  {
    label: t("type"),
    value: "Type",
  },
  {
    label: t("created-on"),
    value: "created_on",
  },
  {
    label: t("city"),
    value: "City",
  },
  {
    label: t("is-active"),
    value: "isActive",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
};
