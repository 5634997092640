<template>
  <nav
    :class="
      is_active
        ? 'translate-x-0 '
        : 'rtl:translate-x-full ltr:-translate-x-full'
    "
    class="fixed z-20 flex flex-col h-screen hiddenScrollbar overflow-auto transition-transform duration-150 ease-in transform bg-white shadow-xl ltr:left-0 rtl:right-0 text-primaryDark lg:static shadow-primary/10"
  >
    <div class="flex items-center justify-between px-5 my-2">
      <router-link to="/">
        <Image
          src="/logo.svg"
          class="object-cover h-[80px] w-[80px] mx-auto"
          alt="enjaz"
          loading="lazy"
        />
      </router-link>
      <div class="flex items-center justify-center gap-3">
        <h6 class="text-lg font-normal lg:text-2xl">
          {{ $t("enjaz") }}
        </h6>
        <span class="font-light text-[10px] whitespace-nowrap">{{
          $t("control-panel")
        }}</span>
      </div>
      <button class="lg:hidden" @click="toggleSidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: rgba(0, 0, 0, 1)"
        >
          <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
        </svg>
      </button>
    </div>

    <template v-for="(route, index) in menu" :key="index">
      <div v-if="route.children.length" class="w-full">
        <button
          @click="route.is_active = !route.is_active"
          :class="route.is_active ? ' border-r-4 border-primary' : ''"
          class="inline-flex flex-row items-center justify-between w-full gap-2 px-5 py-2 text-primaryDark"
        >
          <span class="flex items-center"
            ><i :class="route.icon" class="text-xl"></i>
            <span class="mx-1 text-sm">{{
              route.title
            }}</span></span
          >
          <i
            class="transition-all duration-300 text-primaryDark bx bx-chevron-down"
            :class="{ 'transform rotate-180': route.is_active }"
          ></i>
        </button>
        <ul
          class="w-full pr-3 overflow-hidden transition-all duration-200"
          :class="{ 'max-h-0': !route.is_active, 'max-h-96': route.is_active }"
        >
          <li
            class="w-full px-2"
            v-for="child in route.children"
            :key="child.route_name"
          >
            <router-link
              :to="{
                name: child.route_name,
              }"
              class="flex flex-row items-center w-full gap-2 px-4 py-1 text-primaryDark"
              active-class="font-medium rounded-lg  bg-tertiary text-primary"
            >
              <i :class="child.icon" class="text-xl"></i>
              <span class="mx-1 text-xs leading-10 !whitespace-nowrap">{{
                child.title
              }}</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="px-2" v-else>
        <router-link
          :to="{
            name: route.route_name,
          }"
          class="inline-flex flex-row items-center w-full gap-2 px-4 py-1 text-primaryDark"
          active-class="font-medium rounded-lg bg-tertiary text-primary"
        >
          <i :class="route.icon" class="text-xl"></i>
          <span class="mx-1 leading-10 text-sm !whitespace-nowrap">{{
            route.title
          }}</span>
        </router-link>
      </div>
    </template>
  </nav>
</template>
<script lang="ts" setup>
import navLinks from "@/core/constant/navLinks";
import useHasRole from "@/core/hooks/useHasRole";
import { ref } from "vue";
import { useRouter } from "vue-router";

defineProps<{
  is_active: boolean;
}>();

const emit = defineEmits(["toggleSidebar"]);
const router = useRouter();

const toggleSidebar = () => {
  emit("toggleSidebar");
};

const { has } = useHasRole();

const menu = ref(
  navLinks
    .filter((item) => has(item.allowedPermission))
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          is_active: false,
          children: item.children.map((child) => {
            return {
              ...child,
              is_active: false,
            };
          }),
        };
      } else {
        return {
          ...item,
          is_active: false,
        };
      }
    })
);
</script>
