import { t } from "../i18n";


export default [
  {
    title: t("users"),
    route_name: "users",
    icon: "bx bx-user",
    allowedPermission: 0,
    children: [
      {
        title: t("AllUsers"),
        route_name: "all-users",
        icon: "bx bx-group",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("StudentUsers"),
        route_name: "StudentUsers",
        icon: "bx bx-child",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("TeacherUsers"),
        route_name: "TeacherUsers",
        icon: "bx bx-male",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("ParentUsers"),
        route_name: "ParentUsers",
        icon: "bx bx-male-female",
        allowedPermission: 0,
        children: [],
      },
    ],
  },
  {
    title: t("schools"),
    route_name: "ListOfSubjects",
    icon: "bx bx-book",
    allowedPermission: 0,
    children: [
      {
        title: t("ListOfSchools"),
        route_name: "ListOfSchools",
        icon: "bx bxs-school",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("import-schools"),
        route_name: "ImportSchools",
        icon: "bx bx-world",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("ListOfGrades"),
        route_name: "ListOfGrades",
        icon: "bx bx-layer",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("ListOfSubjects"),
        route_name: "ListOfSubjects",
        icon: "bx bx-book",
        allowedPermission: 0,
        children: [],
      },
    ],
  },
  {
    title: t("list-of-countries"),
    route_name: "ListOfCountries",
    icon: "bx bx-world",
    allowedPermission: 0,
    children: [
      {
        title: t("list-of-countries"),
        route_name: "ListOfCountries",
        icon: "bx bx-world",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("list-of-cities"),
        route_name: "ListOfCities",
        icon: "bx bxs-city",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("import-countries"),
        route_name: "ImportCountries",
        icon: "bx bx-world",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("import-cities"),
        route_name: "ImportCities",
        icon: "bx bx-world",
        allowedPermission: 0,
        children: [],
      },
    ],
  },

  {
    title: t("courses"),
    route_name: "Courses",
    icon: "bx bx-book-open",
    allowedPermission: 0,
    children: [
      {
        title: t("courses"),
        route_name: "Courses",
        icon: "bx bx-book-open",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("packages"),
        route_name: "packagesManagement",
        icon: "bx bx-package",
        allowedPermission: 0,
        children: [],
      },
    ],
  },



  {
    title: t("promotions-management"),
    route_name: "promotionsManagement",
    icon: "bx bx-gift",
    allowedPermission: 0,
    children: [],
  },

  {
    title: t("vouchers-management"),
    route_name: "VoucherBatchManagement",
    icon: "bx bx-card",
    allowedPermission: 0,
    children: [
      {
        title: t("Vouchers"),
        route_name: "VoucherManagement",
        icon: "bx bx-card",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("unique-vouchers"),
        route_name: "UniqueVouchers",
        icon: "bx bx-card",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("distributors"),
        route_name: "DistributorManagement",
        icon: "bx bx-card",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("batches-management"),
        route_name: "VoucherBatchManagement",
        icon: "bx bx-card",
        allowedPermission: 0,
        children: [],
      },
    ],
  },

  {
    title: t("FAQ"),
    route_name: "FAQ",
    icon: "bx bx-help-circle",
    allowedPermission: 0,
    children: [
      {
        title: t("FAQ"),
        route_name: "FAQ",
        icon: "bx bx-help-circle",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("FaqCategory"),
        route_name: "FaqCategory",
        icon: "bx bx-help-circle",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("BUGReportList"),
        route_name: "BUGReportList",
        icon: "bx bx-bug",
        allowedPermission: 0,
        children: [],
      },
    ],
  },

  {
    title: "System Settings",
    route_name: "SystemSettings",
    icon: "bx bx-cog",
    allowedPermission: 0,
    children: [
      {
        title: "System Settings",
        route_name: "SystemSettings",
        icon: "bx bx-cog",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("AdminsManagement"),
        route_name: "admins-management-list",
        icon: "bx bx-user",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("general-subscription"),
        route_name: "generalSubscription",
        icon: "bx bx-package",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("image-management"),
        route_name: "image-management",
        icon: "bx bx-image",
        allowedPermission: 0,
        children: [],
      },
      {
        title: t("policies-management"),
        route_name: "policiesManagement",
        icon: "bx bx-file-blank",
        allowedPermission: 0,
        children: [],
      },
    ],
  },
] as NavLink[];
